import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import Divider from "@mui/material/Divider"

import { IMG_LOGO_DARK } from "../../data/assets"
import { APP_FOOTER } from "../../data/config/info"

const AppFooter = () => {
  return (
    <>
      <Box
        component="img"
        src={IMG_LOGO_DARK}
        height={60}
        mt={8}
        mb={3}
        mx={2}
      />
      <Grid mb={4} container>
        {APP_FOOTER?.contents?.map((content, index) => (
          <Grid item xs={6} sm={4} md={2} p={2} key={index}>
            {content.type === "header" ? (
              <Typography
                variant="subtitle1"
                fontWeight={500}
                sx={{ color: "secondary.main" }}
                gutterBottom
              >
                {content.title}
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                sx={{ color: "secondary.main" }}
                gutterBottom
              >
                {content.title}
              </Typography>
            )}
            {content?.list?.map((listItem, index) => {
              return (
                <Link
                  key={index}
                  href={listItem.url}
                  target="_blank"
                  display="block"
                  variant="subtitle2"
                  color="common.white"
                  underline="hover"
                  gutterBottom
                >
                  {listItem.text}
                </Link>
              )
            })}
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ borderColor: "common.white" }} />
      <Grid py={2} container>
        {APP_FOOTER?.socials?.length > 0 && (
          <Grid item xs={12} sm={6} md={6} p={2}>
            <Typography
              color="common.white"
              variant="subtitle2"
              component="span"
              mr={3}
            >
              Follow Us On
            </Typography>
            {APP_FOOTER.socials.map((social, index) => (
              <IconButton
                key={index}
                color="secondary"
                aria-label={social.label}
                size="small"
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </IconButton>
            ))}
          </Grid>
        )}
        {APP_FOOTER?.extras?.map((listItem, index) => {
          return (
            <Grid item xs={6} md={2} p={2} key={index}>
              <Link
                href={listItem.url}
                display="block"
                variant="subtitle2"
                color="common.white"
                underline="hover"
                gutterBottom
              >
                {listItem.text}
              </Link>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default AppFooter
