import { LOCAL_STORAGE } from "./constants"

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

export const uniqueId = () => {
  let time = new Date().getTime()
  return `${time}${s4()}${s4()}${s4()}`
}

export const log = (...arg) => {
  if (process.env.NODE_ENV !== "production") console.log(...arg)
}

export const clearAllDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE.PRIMARY)
  localStorage.removeItem(LOCAL_STORAGE.SECONDARY)
  localStorage.removeItem(LOCAL_STORAGE.THEME)
}

export const getDataFromLocalStorage = (key, defaultValue) => {
  const dataStr = localStorage.getItem(key)
  try {
    if (dataStr && dataStr !== undefined) {
      const data = JSON.parse(dataStr)
      if (!(data.expiry && data.value)) {
        clearAllDataFromLocalStorage()
        return defaultValue
      }
      if (new Date().getTime() > data.expiry) {
        localStorage.removeItem(key)
        return defaultValue
      }
      return data.value
    }
    return defaultValue
  } catch (error) {
    log("ERROR", error)
    clearAllDataFromLocalStorage()
    return defaultValue
  }
}

export const setDataInLocalStorage = (key, value, ttl = 3600000) => {
  const data = {
    value,
    expiry: new Date().getTime() + ttl,
  }
  const json_data = JSON.stringify(data)
  localStorage.setItem(key, json_data)
}

export const preventDefault = (event) => event.preventDefault()

export const getDeviceData = () => {
  const deviceData = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    orientation: window.matchMedia("(orientation:landscape)").matches
      ? "landscape"
      : "portrait",
  }
  return deviceData
}

export const convertArrayIntoBreakpointGroups = (data = [], size = 3) => {
  if (size === 1) return data
  const groupedData = []
  let temp = []
  data?.forEach((item, index) => {
    temp.push(item)
    if ((index + 1) % size === 0 || index === data.length - 1) {
      groupedData.push(temp)
      temp = []
    }
  })
  return groupedData
}

export const fetchWithTimeout = (url, options, timeout = 10000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    ),
  ])
}

export const isEmail = (address) => {
  let regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  return regex.test(address)
}

export const isValidPhone = number => {
  let regex = new RegExp(/^\+[1-9]{1}[0-9]{3,14}$/)
  return regex.test(number)
}

export const dynamicallyLoadScript = (
  FILE_URL,
  async = true,
  type = "text/javascript"
) => {
  try {
    const scriptEle = document.createElement("script")
    scriptEle.type = type
    scriptEle.async = async
    scriptEle.src = FILE_URL
    document.body.appendChild(scriptEle)
  } catch (error) {
    console.log("dynamicallyLoadScript Error", error)
  }
}
