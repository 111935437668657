import { yellow } from "@mui/material/colors"

const theme = (primary = "#9B65BA", secondary = "#85BB65") => ({
  palette: {
    mode: "dark",
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    warning: {
      main: yellow["600"],
    },
    background: {
      default: "#212121",
    },
  },
})

export default theme
