
const dark = "#1f1f1f"

const theme = (primary = "#9B65BA", secondary = "#85BB65") => ({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      primary: dark,
    },
  },
})

export default theme
