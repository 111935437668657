import { lazy, Suspense, useState, useEffect, useMemo, useRef } from "react"
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"

import "./index.scss"

import { IMG_BACKGROUND } from "../data/assets"
import { LOCAL_STORAGE, THEME_TYPE, ROUTE_PATH, APP_LAYOUT } from "../data/config/constants"
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
  getDeviceData,
} from "../data/config/utils"

import { ThemeModeContext, DeviceContext } from "../data/config/context"
import themeConfig from "../data/theme"

import AppNavbar from "../components/AppNavbar"
import AppFooter from "../components/AppFooter"
import ResetScroll from "../components/ResetScroll"
import ScrollTop from "../components/ScrollTop"
import BoxCard from "../components/BoxCard"

const Home = lazy(() => import("../App/Home"))
const Clients = lazy(() => import("../App/Clients"))
const ConversationalAI = lazy(() => import("../App/ConversationalAI"))
const UseCases = lazy(() => import("../App/UseCases"))
const Awards = lazy(() => import("../App/Awards"))
const Testimonials = lazy(() => import("../App/Testimonials"))

const BookDemo = lazy(() => import("../components/BookDemo"))
const PageNotFound = lazy(() => import("../components/PageNotFound"))

const themeType = getDataFromLocalStorage(
  LOCAL_STORAGE.THEME,
  window.matchMedia("(prefers-color-scheme: dark)").matches
    ? THEME_TYPE.DARK
    : THEME_TYPE.LIGHT
)
const primary = getDataFromLocalStorage(LOCAL_STORAGE.PRIMARY, undefined)
const secondary = getDataFromLocalStorage(LOCAL_STORAGE.SECONDARY, undefined)

const App = () => {
  const [themeMode, setThemeMode] = useState(themeType)
  const [primaryColor, setPrimaryColor] = useState(primary)
  const [secondaryColor, setSecondaryColor] = useState(secondary)
  const [deviceData, setDeviceData] = useState(getDeviceData())
  const [snackBar, setSnackBar] = useState({})

  const resizeTimerRef = useRef(null)

  useEffect(() => {
    const handleDeviceStatusChange = () => {
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
      resizeTimerRef.current = setTimeout(
        () => setDeviceData(getDeviceData()),
        500
      )
    }
    window.addEventListener("resize", handleDeviceStatusChange)

    return () => {
      window.removeEventListener("resize", handleDeviceStatusChange)
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
    }
  }, [])

  const deviceHandler = useMemo(
    () => ({
      deviceData,
      showSnackBar: data => setSnackBar(data),
      closeSnackBar: (e, reason) => {
        if (reason === "clickaway") return
        setSnackBar({})
      },
    }),
    [deviceData]
  )

  const themeHandler = useMemo(
    () => ({
      changeThemeMode: mode => {
        if (mode) {
          setThemeMode(mode)
          setDataInLocalStorage(LOCAL_STORAGE.THEME, mode, 24 * 3600 * 1000)
        }
      },
      changePrimaryColor: color => {
        if (color) {
          setPrimaryColor(color)
          setDataInLocalStorage(LOCAL_STORAGE.PRIMARY, color)
        }
      },
      changeSecondaryColor: color => {
        if (color) {
          setSecondaryColor(color)
          setDataInLocalStorage(LOCAL_STORAGE.SECONDARY, color)
        }
      },
    }),
    []
  )
  const theme = useMemo(
    () => createTheme(themeConfig[themeMode](primaryColor, secondaryColor)),
    [themeMode, primaryColor, secondaryColor]
  )

  return (
    <DeviceContext.Provider value={deviceHandler}>
      <ThemeModeContext.Provider value={themeHandler}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ResetScroll />
            <Box
              display="flex"
              flexDirection="column"
              minHeight="100vh"
              sx={{
                backgroundImage: `url(${IMG_BACKGROUND})`,
                backgroundPosition: "center",
                backgroundRepeat: "repeat",
                backgroundSize: "contain",
              }}
            >
              <Box
                id="back-to-top-anchor"
                sx={{ backgroundColor: "background.default" }}
              >
                <Container maxWidth="xl">
                  <AppNavbar />
                </Container>
              </Box>
              <Box flexGrow={1} position="relative" sx={{ minHeight: "60vh" }}>
                <Suspense
                  fallback={
                    <BoxCard
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        height: `calc(100vh - ${APP_LAYOUT.APP_HEADER_HEIGHT}px + 24px)`,
                      }}
                    >
                      <CircularProgress size={40} />
                    </BoxCard>
                  }
                >
                  <Routes>
                    <Route index path={ROUTE_PATH.HOME} element={<Home />} />
                    <Route path={ROUTE_PATH.CLIENTS} element={<Clients />} />
                    <Route
                      path={ROUTE_PATH.CONVERSATIONAL_AI}
                      element={<ConversationalAI />}
                    />
                    <Route path={ROUTE_PATH.USE_CASES} element={<UseCases />} />
                    <Route path={ROUTE_PATH.AWARDS} element={<Awards />} />
                    <Route
                      path={ROUTE_PATH.TESTIMONIALS}
                      element={<Testimonials />}
                    />
                    <Route path={ROUTE_PATH.BOOKDEMO} element={<BookDemo />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </Box>
              <Container maxWidth="xl">
                <AppFooter />
              </Container>
              {process.env.REACT_APP_VERSION && (
                <Typography
                  variant="caption"
                  align="center"
                  color="common.white"
                >
                  v{process.env.REACT_APP_VERSION}
                </Typography>
              )}
              <Typography variant="caption" align="center" color="common.white">
                © Oriserve 2022 Copyright
              </Typography>
            </Box>
            <ScrollTop />
            <Snackbar
              autoHideDuration={2000}
              onClose={deviceHandler.closeSnackBar}
              {...snackBar}
            >
              {snackBar.alert && (
                <Alert
                  onClose={deviceHandler.closeSnackBar}
                  {...snackBar.alert}
                >
                  {snackBar.message}
                </Alert>
              )}
            </Snackbar>
          </Router>
        </ThemeProvider>
      </ThemeModeContext.Provider>
    </DeviceContext.Provider>
  )
}

export default App
