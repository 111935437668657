export const IMG_404 = require("./img/others/404.png")
export const IMG_LOGO = require("./img/others/logo.png")
export const IMG_LOGO_DARK = require("./img/others/logoDark.png")
export const IMG_BACKGROUND = require("./img/others/background.png")
export const IMG_GOOGLE = require("./img/others/google.png")
export const IMG_CONVERSATIONAL_AI = require("./img/others/conversationalAI.png")
export const IMG_THANKYOU_GIRL = require("./img/others/thankYouGirl.png")

//---------------------------- VIDEO ----------------------------
export const VID_GIRL_CONVERSATION_1 = require("./video/girlConversation1.mp4")
export const VID_GIRL_CONVERSATION_2 = require("./video/girlConversation2.mp4")
export const VID_GIRL_CONVERSATION_1_DARK = require("./video/girlConversation1Dark.mp4")
export const VID_GIRL_CONVERSATION_2_DARK = require("./video/girlConversation2Dark.mp4")
export const VID_MAN_CONVERSATION = require("./video/manConversation.mp4")
export const VID_MAN_CONVERSATION_DARK = require("./video/manConversationDark.mp4")
export const VID_CONVERSATIONAL_AI_1 = require("./video/AIConversation1.mp4")
export const VID_CONVERSATIONAL_AI_1_DARK = require("./video/AIConversation1Dark.mp4")
export const VID_CONVERSATIONAL_AI_2 = require("./video/AIConversation2.mp4")
export const VID_CONVERSATIONAL_AI_2_DARK = require("./video/AIConversation2Dark.mp4")

//---------------------------- BRAND LOGO ----------------------------
export const IMG_BRAND1 = require("./img/brandLogo/100by300/logo1.png")
export const IMG_BRAND2 = require("./img/brandLogo/100by300/logo2.png")
export const IMG_BRAND3 = require("./img/brandLogo/100by300/logo3.png")
export const IMG_BRAND4 = require("./img/brandLogo/100by300/logo4.png")
export const IMG_BRAND5 = require("./img/brandLogo/100by300/logo5.png")
export const IMG_BRAND6 = require("./img/brandLogo/100by300/logo6.png")
export const IMG_BRAND7 = require("./img/brandLogo/100by300/logo7.png")
export const IMG_BRAND8 = require("./img/brandLogo/100by300/logo8.png")
export const IMG_BRAND9 = require("./img/brandLogo/100by300/logo9.png")
export const IMG_BRAND10 = require("./img/brandLogo/100by300/logo10.png")
export const IMG_BRAND11 = require("./img/brandLogo/100by300/logo11.png")
export const IMG_BRAND12 = require("./img/brandLogo/100by300/logo12.png")
export const IMG_BRAND13 = require("./img/brandLogo/100by300/logo13.png")
export const IMG_BRAND14 = require("./img/brandLogo/100by300/logo14.png")
export const IMG_BRAND15 = require("./img/brandLogo/100by300/logo15.png")
export const IMG_BRAND16 = require("./img/brandLogo/100by300/logo16.png")
export const IMG_BRAND17 = require("./img/brandLogo/100by300/logo17.png")
export const IMG_BRAND18 = require("./img/brandLogo/100by300/logo18.png")

export const IMG_BRAND4_DARK = require("./img/brandLogo/100by300/logo4Dark.png")
export const IMG_BRAND10_DARK = require("./img/brandLogo/100by300/logo10Dark.png")
export const IMG_BRAND13_DARK = require("./img/brandLogo/100by300/logo13Dark.png")
export const IMG_BRAND14_DARK = require("./img/brandLogo/100by300/logo14Dark.png")


//---------------------------- CLIENTS ----------------------------
export const IMG_CLIENT1 = require("./img/clientAvatar/200by200/6thStreet.png")
export const IMG_CLIENT2 = require("./img/clientAvatar/200by200/bajaj.png")
export const IMG_CLIENT3 = require("./img/clientAvatar/200by200/google.png")
export const IMG_CLIENT4 = require("./img/clientAvatar/200by200/microsoft.png")
export const IMG_CLIENT5 = require("./img/clientAvatar/200by200/vi.png")
