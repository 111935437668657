import React from "react"
import ReactDOM from "react-dom/client"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { dynamicallyLoadScript } from "./data/config/utils"
import { BRAND_INFO } from "./data/config/info"

const root = ReactDOM.createRoot(document.getElementById("ori-website-2.0"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

dynamicallyLoadScript(process.env.REACT_APP_CHATBOT_URL)

if (process.env.REACT_APP_ALLOW_GTM === "true") {
  dynamicallyLoadScript(BRAND_INFO.googleTagManagerUrl)
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag("js", new Date())
  gtag("config", process.env.REACT_APP_GTM_KEY)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
