import PropTypes from "prop-types"
import Box from "@mui/material/Box"

const BoxCard = ({ children, sx, ...rest }) => {
  return (
    <Box
      component="main"
      p={4}
      className="ori-animated ori-fade-in"
      sx={{
        backgroundColor: "background.default",
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

BoxCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sx: PropTypes.object,
}

BoxCard.defaultProps = {
  children: null,
  sx: {},
}

export default BoxCard
