import { useContext, useState, useEffect } from "react"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import MenuIcon from "@mui/icons-material/Menu"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { NAV_BAR, THEME_TYPE, ROUTE_PATH } from "../../data/config/constants"
import { IMG_LOGO, IMG_LOGO_DARK } from "../../data/assets"
import { ThemeModeContext } from "../../data/config/context"

const AppNavBar = () => {
  const theme = useTheme()
  const location = useLocation()
  const navigateTo = useNavigate()
  const themeHandler = useContext(ThemeModeContext)
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  useEffect(() => {
    setMobileDrawerOpen(false)
  }, [location.pathname])

  const handleDrawerToggle = () => {
    setMobileDrawerOpen((prevState) => !prevState)
  }

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar position="relative" sx={{ justifyContent: "space-between" }}>
          <Box
            component="img"
            height={50}
            src={
              theme.palette.mode === THEME_TYPE.DARK ? IMG_LOGO_DARK : IMG_LOGO
            }
            alt="ORI"
            sx={{ cursor: "pointer" }}
            onClick={() => navigateTo(ROUTE_PATH.HOME)}
          />
          <IconButton
            aria-label="open drawer"
            edge="start"
            sx={{ position: "absolute", right: 0, display: { lg: "none" } }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box
            alignSelf="baseline"
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: { xs: "none", lg: "flex" },
            }}
          >
            {NAV_BAR.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="nav"
                  mt={2}
                  pt={1}
                  pb={0.5}
                  mx={{ md: 4 }}
                  alignSelf="baseline"
                  sx={
                    location.pathname === item.route
                      ? {
                          transition: "all 250ms ease",
                          color: "secondary.main",
                          borderBottom: 2,
                          borderImageSlice: 1,
                          borderColor: "secondary.main",
                          "&:hover": {
                            opacity: 0.5,
                            transition: "all 250ms ease",
                            transform: "translateY(-3px)",
                          },
                        }
                      : {
                          "&:hover": {
                            opacity: 0.5,
                            transition: "all 250ms ease",
                            transform: "translateY(-3px)",
                          },
                        }
                  }
                >
                  <Link
                    component={RouterLink}
                    to={item.route}
                    color="inherit"
                    underline="none"
                    fontWeight={500}
                  >
                    {item.name}
                  </Link>
                </Box>
              )
            })}
            <Box
              component="nav"
              alignSelf="baseline"
              pt={1}
              mx={{ xs: 1.5, sm: 4, md: 4 }}
              sx={{
                cursor: "pointer",
                transition: "all 250ms ease",
                "&:hover": {
                  opacity: 0.5,
                  transition: "all 250ms ease",
                  transform: "translateY(-3px)",
                },
              }}
            >
              {theme.palette.mode === THEME_TYPE.DARK ? (
                <IconButton
                  color="inherit"
                  onClick={() => themeHandler.changeThemeMode(THEME_TYPE.LIGHT)}
                >
                  <Brightness7Icon color="warning" />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  onClick={() => themeHandler.changeThemeMode(THEME_TYPE.DARK)}
                >
                  <Brightness4Icon color="inherit" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            component="nav"
            alignSelf="baseline"
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigateTo(ROUTE_PATH.BOOKDEMO)}
            >
              Book a demo
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: 300,
            backgroundColor: "background.default",
            backgroundImage: "none",
          },
        }}
        open={mobileDrawerOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          component="img"
          height={60}
          mt={1}
          mb={2}
          mx={2}
          alignSelf="flex-start"
          src={
            theme.palette.mode === THEME_TYPE.DARK ? IMG_LOGO_DARK : IMG_LOGO
          }
          alt="ORI"
        />
        <List sx={{ marginBottom: 2 }}>
          {NAV_BAR.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                color:
                  location.pathname === item.route
                    ? "secondary.main"
                    : "inherit",
              }}
              disablePadding
            >
              <ListItemButton component={RouterLink} to={item.route}>
                {item.Icon && (
                  <ListItemIcon>
                    {
                      <item.Icon
                        sx={{
                          color:
                            location.pathname === item.route
                              ? "secondary.main"
                              : "inherit",
                        }}
                      />
                    }
                  </ListItemIcon>
                )}
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <FormControlLabel
          sx={{ margin: 1 }}
          control={
            <Switch
              size="medium"
              color="secondary"
              inputProps={{ "aria-label": "controlled" }}
              checked={theme.palette.mode === THEME_TYPE.DARK}
              onChange={(e) =>
                themeHandler.changeThemeMode(
                  e.target.checked ? THEME_TYPE.DARK : THEME_TYPE.LIGHT
                )
              }
            />
          }
          label={theme.palette.mode}
        />
        <Button
          sx={{ margin: 2 }}
          variant="contained"
          size="large"
          onClick={() => navigateTo(ROUTE_PATH.BOOKDEMO)}
        >
          Book a demo
        </Button>
        <Button
          size="large"
          sx={{ position: "absolute", bottom: 0 }}
          onClick={handleDrawerToggle}
          fullWidth
        >
          <ChevronRightIcon />
        </Button>
      </Drawer>
    </>
  )
}

export default AppNavBar
